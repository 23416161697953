.customize-botton {
  background-color: #00bd6d;
  border-color: #00bd6d;
}

.site-layout .site-layout-background {
  background: #00bd6d !important;
  box-shadow: 0px 0px 5px rgba(18, 23, 39, 0.5);
  position: relative;
  z-index: 1;
}
.list-head {
  background-color: #00bd6d !important;
  color: #fff;
  padding: 1rem 2rem 2rem 2rem;
  height: 180px;
  margin-bottom: 0;
}
.login-wrap {
  background: #00bd6d !important;
}
.ant-tabs-tab:focus {
  color: #00bd6d !important;
}

.ant-menu-item a:hover {
  color: #00bd6d !important;
}
.ant-menu-item a:focus {
  color: #00bd6d !important;
}

.site-layout .site-layout-background {
  padding: 0 !important;
  background-color: #00bd6d;
}

.summaryButton.summaryButton2 {
  border-radius: 3px;
  background-color: #00bd6d;
}

.manager-btn button {
  background-color: #00bd6d;
  border-color: #00bd6d;
}
.ant-btn-primary {
  background-color: #00bd6d !important;
  border-color: #00bd6d !important;
}
.ant-btn-primary:focus {
  background-color: #00bd6d !important;
  border-color: #00bd6d !important;
}
.repair-text h1 {
  color: #00bd6d;
  font-size: 28px;
}
.customize-botton2 {
  background-color: #f5f7f6 !important;
  color: #000;
  border: solid 1px #00bd6d;
  border-radius: 7px;
}
.customize-botton2:hover {
  background-color: #f5f7f6 !important;
  color: #000;
  border: solid 2px #000;
  border-radius: 7px;
  transition-delay: 0.2s;
}

/* *************************************************************** Loading *************************************************************** */

.loading-container {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-smooth: auto;
  font-weight: 300;
  line-height: 1.5;
  color: #444;
}

.loading-body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.loader {
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 2px;
  width: 100px;
  height: 100px;
}
.loader > div {
  position: relative;
  width: 100%;
  height: 100%;
  background: #00bd6d;
  transform: scale(0);
  transform-origin: center center;
  animation: loader 2s infinite linear;
}
.loader > div:nth-of-type(1),
.loader > div:nth-of-type(5),
.loader > div:nth-of-type(9) {
  animation-delay: 0.4s;
}
.loader > div:nth-of-type(4),
.loader > div:nth-of-type(8) {
  animation-delay: 0.2s;
}
.loader > div:nth-of-type(2),
.loader > div:nth-of-type(6) {
  animation-delay: 0.6s;
}
.loader > div:nth-of-type(3) {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

/* *************************************************************** Loading *************************************************************** */
