body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
 .login-form {
  max-width: 500px;
  min-width: 350px;
  
}
.login-form-forgot {
  float: right;
}
 .ant-col-rtl .login-form-forgot {
  float: left;
}
 .login-form-button {
  width: 100%;
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}
@font-face {
  font-family: 'Gilroy Extra';
  src: url(/fonts/Gilory/Gilroy-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy Black';
  src: url(/fonts/Gilory/Gilroy-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy Italic';
  src: url(/fonts/Gilory/Gilroy-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy Regular';
  src: url(/fonts/Gilory/Gilroy-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Gilroy Medium';
  src: url(/fonts/Gilory/Gilroy-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy Bold';
  src: url(/fonts/Gilory/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  src: url(/fonts/Gilory/Gilroy-Light.ttf) format('truetype');
}
span {
  font-family: 'Gilroy Medium';
}
td {
  font-family: 'Gilroy Medium';
}

button > i {
  margin-right: 5px;
}
a {
  text-decoration: none !important;
}
.ant-layout {
  background: #f9fbfd;
}
.test {
  color: var(--header-color);
}
.ant-input {
  font-size: 13px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #02213e;
}

.ant-layout-header {
  height: 62px;
}
.ant-select-selection-item {
  font-size: 13px;
}

.logo-wrap {
  background-color: #000 !important;
  padding: 10px 10px 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px rgba(18, 23, 39, 0.5);
}

.logo {
  color: white;
  padding: 0.3rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  margin: 0;
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
}
.logo img {
  width: 80%;
}

.ant-layout-sider-collapsed .logo {
  background-position: center;
}
.ant-layout-sider-collapsed .logo img {
  width: 100%;
}

.ant-layout-sider-light {
  background: #fff;
  box-shadow: 4px 4px 10px rgba(69, 65, 78, 0.06);
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.topMenu {
  display: flex;
}

.topMenu > div {
  padding: 0 15px;
}

.topMenu > div > a {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 20px !important;
}

.settings {
  color: #fff;
  align-items: center;
}
.logs {
  color: #fff;
  align-items: center;
}

.list-head {
  margin-top: -0.1rem !important;
  height: 13.5vh !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.list-title {
  margin-bottom: 0 !important;
  padding: 0;
}
.list-title h3 {
  color: #fff !important;
}
.ant-breadcrumb li {
  color: #fff;
}
.list-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ant-breadcrumb-separator {
  color: #fff;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: none !important;
  background-color: #444;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item {
  justify-content: start;
}
.ant-menu-item-selected {
  background-color: #f5f5f5 !important;
  color: #02213e;
}

.ant-layout-sider-collapsed .ant-menu-item img {
  width: 40px;
  height: 35px;
  margin-left: -3px;
}

.list-head h1 {
  font-weight: 600;
  color: #fff;
  font-size: 1.35rem;
  margin: 0;
}

.table-wrap,
.form-wrap {
  margin-top: -21px;
  padding-right: 2rem;
  padding-left: 2rem;
}
.form {
  display: flex;
  justify-content: center;
}

.form-back {
  margin: 20px;
  margin-bottom: 35vh;
}

.login-card {
  background-color: #fff;
  width: 50%;
}

.super-admin {
  background: #fff !important;
}
.pricecardimage img {
  width: 100%;
}
.login-image img {
  width: 60%;
}
.login-image {
  padding: 35px;
  display: flex;
  justify-content: center;
}
.super-admin .form {
  align-items: center;
}

.login-wrap .logo {
  margin: 0;
  margin-bottom: 20px;
  margin-top: 150px;
  height: 60px;
  background-position: left;
}
.modal-background {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  height: 435px;
}
.modal-background img {
  width: 85% !important;
  opacity: 0.1;
}
.head-menu {
  width: 250px;
}
.stationsummary ul {
  padding-left: 0px !important;
}
.stationsummary ul li {
  list-style: none;
}
.stationinfo {
  padding: 10px;
  width: 715px;
  height: 300px;
  border-radius: 20px;
}
.stationinfo li {
  display: flex;
}
.ant-form-large .ant-form-item-label > label {
  min-width: 150px;
}

.main-wrap .ant-row .ant-col {
  padding: 0 15px;
}
.stationinfo__ li {
  padding: 10px;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 7px 10px;
  font-size: 14px;
  background: #75d2e429;
  border: 0;
  outline: none;
  margin: 0 0 0 16px !important;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgba(0, 0, 0, 0.85) !important;
}
.ant-tabs-tab-active {
  outline: none !important;
  overflow: hidden;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}

.ant-modal-title {
  color: #0e798e52;
}

.ant-tabs-tab:hover {
  color: rgba(0, 0, 0, 0.85) !important;
}
.dragDrop {
  padding: 10px;

  background: #fff;
}
.dragDrop li {
  display: flex;
  border: 1px solid #ccc;
  margin: 5px 0;
  padding: 5px 15px;
  justify-content: space-between;
  align-items: center;
}

.rightButtons > button {
  margin: 0 5px;
}

.moduleLiHover li:hover {
  background-color: #f9fbfd;
  border: 1px solid red;
}

.nameDrag {
  font-size: 15px;
  font-weight: 600;
  height: 100%;
  align-items: center;
  float: right;
  margin-left: 15px;
}

.table-wrap .ant-input-search .ant-input-suffix span {
  width: 40px;
}

.table-wrap .ant-input-search {
  width: 100% !important;
  padding-left: 0;
  border-color: #f0f0f0 !important;
  border-radius: 0;
}

.table-wrap .ant-input-search .ant-input-group .ant-input-group-addon {
  padding: 0;
  border: 0;
  position: absolute;
  right: 40px;
  top: 8px;
  left: auto;
}

.table-wrap .ant-input-search .ant-input-group input {
  height: 45px;
  border-radius: 0;
}
.table-wrap .ant-input-search .ant-input-group input:focus {
  outline: none !important;
  border: none;
}

.table-wrap .ant-input-search > .ant-input-group button {
  padding: 0;
  border: 0;
  z-index: 99;
}

.table-wrap .ant-input-search > .ant-input-group button span {
  font-size: 20px;
}

.timer__bg {
  width: 65px;
  height: 65px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 5px 0px rgba(204, 204, 204, 1);
  font-weight: bold;
  font-size: 26px;
  color: #444;
  border-radius: 50%;
}

.users__title {
  font-size: 18px;
  font-weight: bold;
}

.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-item a img {
  width: 30px;
  background: #ffffff;
  border-radius: 50px;
  padding: 0;
  margin-right: 10px;
}

.true-circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #4caf50;
}

.false-circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #f44336;
}
.warn-circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #FF8400;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-100 {
  width: 100% !important;
}

th.editColumn {
  text-align: center !important;
}

.ant-menu.ant-menu-light.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical,
.ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}

.logo {
  margin: 0 !important;
}

body {
  overflow: hidden;
  height: 100vh;
}

.ant-card,
.table-wrap {
  height: calc(100vh);
  overflow-y: auto;
}

.ant-form .ant-form-item .ant-form-item-control {
  padding: 0;
}

.ant-alert {
  padding: 10px;
}
.ant-picker,
.ant-input-number,
.ant-select {
  width: 100% !important;
}
.ant-scrolling-effect {
  width: 100vw !important;
}
.ant-btn:hover {
  background-color: rgb(241, 236, 236);
  color: rgba(0, 0, 0, 0.85);
  box-shadow: 0px 19px 10px -15px #111;
  transition: all 0.5;
}
.imageUrlbuttons {
  display: flex;
}
.ant-modal-content {
  border-radius: 10px;
  background-color: #fcfff9;
  width: 110vh !important;
}
.pricecard {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  padding: 9px;
  position: relative;
}

.pricecard ul {
  padding-left: 0px !important;
}
.pricecard li {
  padding: 10px;
  list-style: none;
}
.ant-btn:active {
  border-color: none !important;
}
.modal-background-second {
  width: 88%;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: right;
  height: 380px;
}
.modal-background-second img {
  width: 60%;
  opacity: 0.3;
}
.modal__info {
  margin-top: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110%;
}
.tabpanecontent {
  width: 100%;
}
@media only screen and (max-width: 1024px) {
  .login-wrap .form {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-back {
    margin-bottom: 0px;
  }
  .table-wrap,
  .list-head,
  .form-wrap {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .ant-menu-inline-collapsed {
    width: 55px;
  }

  .ant-layout-sider-collapsed .ant-menu-item img {
    width: 40px;
    margin-left: -5px;
  }

  .ant-layout-sider-collapsed.ant-layout-sider-light {
    width: 55px !important;
    max-width: 55px !important;
    min-width: 55px !important;
    flex: 0 0 55px;
    height: 100vh;
    overflow-y: auto;
  }

  .ant-layout-sider-collapsed .ant-layout-sider-trigger {
    width: 55px !important;
  }

  .ant-menu.ant-menu-light.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical,
  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    height: calc(100vh - 115px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ant-card-body,
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    padding: 0 !important;
  }

  .ant-card-head {
    padding: 0 15px;
  }

  .list-head h1 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1920px) {
  .ant-card,
  .table-wrap {
    height: 85vh !important;
    overflow-y: auto !important;
  }
}

@media only screen and (max-width: 1024px) {
  .ant-table-content {
    overflow-x: auto;
  }

  .ant-checkbox-wrapper {
    display: none;
  }

  .notfound-image {
    width: 100%;
    height: 100%;
  }

  .font-mobile {
    font-size: x-large !important;
  }
}

.mt-50 {
  margin-top: 50px;
}

.text-center {
  text-align: center;
}

.font-xl {
  font-size: xxx-large;
}

.font-bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.max-height {
  max-height: 100%;
}
.multipleChoiceSelectOptions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 15px;
}
.freeLengthChoice .ant-input-number {
  max-width: 250px;
  width: 250px;
  margin-left: 5px;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.summaryButton {
  font-size: 13px;
  font-weight: 600;
  color: #fff !important;
  cursor: pointer;
  margin: 20px;
  height: 30px;
  width: 80%;
  position: relative;
  right: 1.2rem;
  text-align: center;
  border: none;
  transition: all 0.4s ease-in-out;
}

.summaryButton:hover {
  background-position: 100% 0;
  transition: all 0.4s ease-in-out;
}
.stationsummary {
  display: flex;
  justify-content: space-around;
}
.summaryButton:focus {
  outline: none;
}

.ant-modal-body ul {
  padding-left: 28px !important;
}

.logomini {
  display: none;
}

.ant-btn-primary:hover {
  color: white;
}

.ant-table-tbody > tr > td {
  padding: 15px;
}

.repair-page img {
  width: 50%;
  height: 100%;
  opacity: 0.4;
}
.repair-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.statistics {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.repair-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  display: flex;
  justify-content: space-around;
}
.ant-radio-button-wrapper {
  margin-right: 10px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  width: 50px;
  height: 50px;
}
.tabpane {
  height: 350px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.ant-tabs {
  overflow: inherit;
}

@media screen and (max-width: 1024px) {
  .logo {
    padding: 1rem;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    margin: 0;
  }
  .logomini {
    display: flex;
  }
}

.calendar {
  margin: 1rem 1rem 15rem 1rem !important;
  padding-right: 2rem;
  padding-left: 2rem;
  max-height: 75vh !important;
  overflow-y: scroll !important;
}

.anticon svg {
  margin-top: -5px !important;
}
.fc .fc-col-header-cell-cushion {
  /* needs to be same precedence */
  padding-top: 5px; /* an override! */
  padding-bottom: 5px; /* an override! */
  display: inline-block !important;
  padding: 2px 4px;
}
.fc .fc-col-header-cell-cushion {
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block !important;
  padding: 2px 4px;
}
.fc .fc-day-number {
  font-size: 1.5em;
  color: #5c005c;
}

.fc .fc-day-header {
  font-size: 1.5em;
  color: #00b294;
}
.fc .fc-media-screen .fc-direction-ltr .fc-theme-standard {
  border-color: #00b294;
  height: 100% !important;
}

.dividerStyle {
  padding: 0 !important;
}

.save-button {
  background-color: #094fa3;
  border-color: transparent;
}
.disabled-row {
  background-color: #ddd;
}

.ant-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 75% !important;
}
.ant-modal-confirm-body,
.ant-modal-confirm-btns {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

/* comment  */
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  color: #00b294;
}
.comment-area {
  padding: 1rem;
  background-color: #d8d5d4;
  border-radius: 7px;
  margin: 1rem 0;
  font-weight: bold;
  max-height: 21rem;
  overflow-y: auto;
}

.comment-area-responsible {
  text-align: end;
  font-size: 1rem;
  color: #00b294;
}
.loading {
  position: absolute !important;
  bottom: 1rem !important;
  right: 1.3rem !important;
}
.ant-btn {
  border-radius: 4px;
}
.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar {
  width: 6px;
}

.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar-thumb {
  background-color: red;
}

.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline::-webkit-scrollbar-track {
  background-color: #ecfdf5;
}
.side-menu,
.ant-menu-dark,
.ant-layout-sider-trigger,
.ant-menu-inline,
.ant-menu-title-content,
.ant-menu-item-selected,
.ant-menu-inline.ant-menu-sub {
  background-color: #222222 !important;
  color: #dddddd !important;
  font-family: 'Gilroy Medium';
}
.ant-menu-title-content:last-child > a:last-child::after {
  color: #000 !important;
  font-family: 'Georgia Medium';
}
.ant-menu-title-content > a > :last-child {
  font-weight: 500;
}
.ant-menu-title-content {
  background-color: #222222 !important;
  color: #dddddd !important;
  font-family: 'Gilroy Medium';
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: #222222 !important;
  color: #dddddd !important;
  font-family: 'Gilroy Medium';
}
.ant-form-large .ant-form-item-label > label {
  font-family: 'Gilroy Medium';
}
.table-wrap .ant-input-search .ant-input-group > input {
  border-radius: 5px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}
.customize-botton {
  background-color: #00bd6d;
  border-color: #00bd6d;
}

.site-layout .site-layout-background {
  background: #00bd6d !important;
  box-shadow: 0px 0px 5px rgba(18, 23, 39, 0.5);
  position: relative;
  z-index: 1;
}
.list-head {
  background-color: #00bd6d !important;
  color: #fff;
  padding: 1rem 2rem 2rem 2rem;
  height: 180px;
  margin-bottom: 0;
}
.login-wrap {
  background: #00bd6d !important;
}
.ant-tabs-tab:focus {
  color: #00bd6d !important;
}

.ant-menu-item a:hover {
  color: #00bd6d !important;
}
.ant-menu-item a:focus {
  color: #00bd6d !important;
}

.site-layout .site-layout-background {
  padding: 0 !important;
  background-color: #00bd6d;
}

.summaryButton.summaryButton2 {
  border-radius: 3px;
  background-color: #00bd6d;
}

.manager-btn button {
  background-color: #00bd6d;
  border-color: #00bd6d;
}
.ant-btn-primary {
  background-color: #00bd6d !important;
  border-color: #00bd6d !important;
}
.ant-btn-primary:focus {
  background-color: #00bd6d !important;
  border-color: #00bd6d !important;
}
.repair-text h1 {
  color: #00bd6d;
  font-size: 28px;
}
.customize-botton2 {
  background-color: #f5f7f6 !important;
  color: #000;
  border: solid 1px #00bd6d;
  border-radius: 7px;
}
.customize-botton2:hover {
  background-color: #f5f7f6 !important;
  color: #000;
  border: solid 2px #000;
  border-radius: 7px;
  transition-delay: 0.2s;
}

/* *************************************************************** Loading *************************************************************** */

.loading-container {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-smooth: auto;
  font-weight: 300;
  line-height: 1.5;
  color: #444;
}

.loading-body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.loader {
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 2px;
  width: 100px;
  height: 100px;
}
.loader > div {
  position: relative;
  width: 100%;
  height: 100%;
  background: #00bd6d;
  transform: scale(0);
  transform-origin: center center;
  -webkit-animation: loader 2s infinite linear;
          animation: loader 2s infinite linear;
}
.loader > div:nth-of-type(1),
.loader > div:nth-of-type(5),
.loader > div:nth-of-type(9) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.loader > div:nth-of-type(4),
.loader > div:nth-of-type(8) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.loader > div:nth-of-type(2),
.loader > div:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.loader > div:nth-of-type(3) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

@-webkit-keyframes loader {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes loader {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

/* *************************************************************** Loading *************************************************************** */

